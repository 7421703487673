<template>
  <div id="app" v-cloak>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  name: 'App',
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  #app {
    max-width: 460px;
    margin: 0 auto ;
    position: relative;
  }
  [v-cloak] {
      display: none;
  }
</style>
