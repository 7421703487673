<template>
  <Eject  v-if="Ejectnes"/>
</template>

<script>
import Eject from '@/components/Eject';
export default {
    data() {
      return {
        Ejectnes:true
      }
    },
    components:{
      Eject
    },
}
</script>

<style>

</style>