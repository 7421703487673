import Vue from 'vue'
import App from './App.vue'
//引入路由
import router from '@/router'
//引入仓库
import store from '@/store'
//引入 组件
import assembly from '@/assembly'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
Vue.use(assembly)
Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router,
  store  
}).$mount('#app')
