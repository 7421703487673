<template>
  <div>
    <Banner/>
    <!--弹出层-->
    <ul class="ncol">
      <li @click='Tabulationid'>
        <img src="@/assets/nc-1.png">
        <div class="text">
          <h1><i>联系客服</i></h1>
          <p>专业的售前售后支持和解决方案</p>
        </div>
        <i class="el-icon-arrow-right jis"></i>
      </li>
      <li @click="Videos()">
        <img src="@/assets/nc-2.png">
        <div class="text">
          <h1><i>操作视频</i></h1>
          <p>直观的产品操作步骤说明</p>
        </div>
        <i class="el-icon-arrow-right jis"></i>
      </li>
      <li @click="Qualification()">
        <img src="@/assets/nc-3.png">
        <div class="text">
          <h1><i>产品资质</i></h1>
          <p>国家标准认证，环保、健康、安全</p>
        </div>
        <i class="el-icon-arrow-right jis"></i>
      </li>
    </ul>
    <div>
      <img src="@/assets/cion-1.jpg" class="imgs">
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner';
export default {
    components:{
      Banner,
    },
    methods: {
      Videos(){
          this.$router.push({
          name:'Videos',
            query:{
              price:2,
            }
        })
      },
      Qualification(){
        this.$router.push({
            name:'Qualification',
            query:{
              price:2,
            }
        })
      },
        Tabulationid(){
        this.$router.push({
            name:'ProjectType'
        })
      },
      openFullScreen(text) {
        const loading = this.$loading({
          lock: true,
          text: text,
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
        }, 5000);
      }
    },
}
</script>

<style>
  .imgs{
    width: 90%;
    margin: 8px auto;
    border-radius: 15px;
  }
  body{
    background-color: #f7f7f7;
  }
  .ncol{
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    margin-top: -2rem;
    position: relative;
    z-index: 9;
  }
  .ncol li{
    background-color: #fff;
    overflow: hidden;
    padding: 6%;
    margin-bottom: 18px;
    color: #333;
  }
  .ncol li a{
    color: #333;
  }
  .ncol li img{
    float: left;
    width: 50px;
  }
  .ncol li .text{
    float: left;
    margin-left: 10%;
  }
  .ncol li .text h1{
    text-align: left;
    position: relative;
  }
  .ncol li .text h1::after{
    position: absolute;
    content:"";
    width: 74px;
    height: 4px;
    bottom: 2px;
    left: 0;
    background-color: #b7e0ff;
    z-index: 1;
  }
  .ncol li .text h1 i{
    position: relative;
    z-index: 9;
  }
  .ncol li .text p{
    margin-top: 17px;
    color: #999;
    font-size: 12px;
  }
  .jis{
    float: right;
    font-size: 18px;
    margin-top: 18px;
    color: #999;
  }
</style>