<template>
<div class="demo1-video">  
 <video id="myVideo"
   style="width: 100%;height: 100%;"
   class="video-js vjs-default-skin vjs-big-play-centered"
   controls

   preload="auto"
   :poster="img">
   <source :src="videoUrl" type="video/mp4" >
  </video>   
 </div>
</template>
<script>

  export default {
    props: {
      img: {
        type: String,
        default: ''
      },
      videoUrl: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        player: null,
      }
    },

  }
</script>
<style scoped>
  /* 将videojs  视频铺满容器 */
  .video-js .vjs-tech {
    object-fit: fill;
  }
</style>