//引入Vue核心库
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
//应用Vuex插件
Vue.use(Vuex)
//仓库储存数据
import state from './state'
//修改state唯一手段
import mutations from './mutations'
//处理actions,可以写自己的业务逻辑,也可以异步
import actions from './actions'
//计算属性,用于简化仓库数据,让组件获取仓库的数据更方便
import getters from './getters'
const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
export default store