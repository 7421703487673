import Vue from 'vue';
import VueRouter from 'vue-router';
import Userpages from './Userpages.js'
Vue.use(VueRouter);
const router= new VueRouter({
    // 跳转到下一个页面的顶部
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes:[
        ...Userpages,
        //重定向,在项目跑起来的时候,访问/,立马让他定向首页
        {
            path:'*',
            redirect:"/Home"
        }
    ]
})
export default router