
import ElementUI from 'element-ui';                           //ElementUI
import VueLazyload from 'vue-lazyload';                       //图片懒加载
import 'animate.css';                                          //https://animate.style/
import { WOW } from 'wowjs';                                  //让页面滚动更有趣   
export default (Vue) => {
    Vue.use(ElementUI);
    Vue.use(VueLazyload, {
        // preLoad: 1.3,                                      //预加载的宽高比
        loading: '/images/public/loading.svg',                //图片加载状态下显示的图片
        error: '/images/public/defaultImgSmall.png',          //图片加载失败时显示的图片
        attempt: 1,                                           //加载错误后最大尝试次数
    });
    Vue.prototype.$wow = new WOW({
        boxClass: 'wow',                                      //需要执行动画的元素的 class
        animateClass: 'animated',                             //animation.css 动画的 class
        offset: 0,                                            //距离可视区域多少开始执行动画
        mobile: true,                                         //是否在移动设备上执行动画
        live: true,                                           //异步加载的内容是否有效
    });
}
