<template>
  <div>
    <div class="toplus">
      {{ pageNum }} / {{ pageTotalNum }}
    </div>
    <pdf
      :page="pageNum"
      :src="url"
      @progress="loadedRatio = $event"
      @num-pages="pageTotalNum = $event"
    ></pdf>
    <div class="botton">
      <div @click="prePage">上一页</div>
      <div @click="nextPage">下一页</div>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
export default {
  components: {
    pdf,
  },
  data() {
    return {
      url: "",
      pageNum: 1,
      pageTotalNum: 1, //总页数
      loadedRatio: 0, //当前页面的加载进度，范围是0-1 ，等于1的时候代表当前页已经完全加载完成了
    };
  },
    mounted() {
        this.url=this.$route.query.url;
        console.log(this.url)
    },
  created() {},
  methods: {
    // 上一页
    prePage() {
      let page = this.pageNum;
      page = page > 1 ? page - 1 : this.pageTotalNum;
      this.pageNum = page;
    },

    // 下一页
    nextPage() {
      let page = this.pageNum;
      page = page < this.pageTotalNum ? page + 1 : 1;
      this.pageNum = page;
    },
  },
};
</script>
<style scoped>
  .toplus{
    width: 100%;
    height: 28px;
    background-color: #008cd7;
    color: #fff;
    font-size: 14px;
    line-height: 28px;
  }
  .botton{
    display: flex;
    justify-content: center;
    margin-top: 5%;
  }
  .botton div{
    width: 80px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #008cd7;
    color: #fff;
    border-radius: 5px;
  }
  .botton div:nth-child(1){
    margin-right: 6%;
  }
</style>
